<header>
  <nav class="navbar navbar-expand-lg top-nav shadow">
    <div class="container">
      <a class="navbar-brand">
        <img src="assets/nubians/logo.png" alt="" />
      </a>
      <button
        class="navbar-toggler collapsed"
        type="button"
        (click)="toggleMenu()"
        data-dismiss="modal"
        style="color: white; font-weight: 500; padding: 5px"
      >
        <div class="hamburger-toggle">
          <div class="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        Menu
      </button>
      <div class="collapse navbar-collapse" id="navbar-content">
        <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/cards"
              >Cards</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLinkActive="active"
              routerLink="/activities"
              >Activitiy</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/contact"
              >Contact</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/requests"
              >Request</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/messages"
              >Messages</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/profile"
              >Profile</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLinkActive="active"
              routerLink="/live-chat"
              >Live Chat</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLinkActive="active"
              style="cursor: pointer"
              (click)="logout()"
              >Logout</a
            >
          </li>
        </ul>

        <form class="d-flex ms-auto" style="position: relative">
          <div class="input-group">
            <input
              id="searchInput"
              style="padding-left: 6px"
              class="form-control mr-2"
              type="search"
              placeholder="Search friends, photos"
              aria-label="Search"
              [(ngModel)]="query"
              name="searchInput"
              (keyup)="searchUsers()"
              autocomplete="off"
            />
            <button
              class="btn btn-dark border-0"
              style="
                padding-top: 2px;
                padding-bottom: 2px;
                padding-left: 5px;
                padding-right: 5px;
              "
              type="submit"
            >
              Search
            </button>
          </div>
          <div
            style="
              background-color: rgb(245, 245, 245);
              position: absolute;
              z-index: 100;
              width: 100%;
              padding: 20px;
              border-radius: 10px;
              color: black;
              top: 40px;
            "
            *ngIf="searchResults.length > 0 && query != ''"
            class="search-results"
          >
            <div
              (click)="closeSearch()"
              [routerLink]="'/user/' + result.id"
              *ngFor="let result of searchResults"
              class="result-item"
              style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                margin-bottom: 10px;
                cursor: pointer;
              "
            >
              <img
                src="assets/images/avatar.png"
                style="height: 30px; width: 30px"
                alt="{{ result.firstname }}"
                class="avatar-image"
              />
              <p style="font-size: 18px">
                {{ result.firstname + " " + result.lastname }}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </nav>
</header>
