   <!-- Navbar start -->
   <section id="header" style="background:#1679AB!important;">
    <div class="overlay"></div>
<div class="container-fluid">
    
    <div class="container px-0">
        <nav class="navbar  navbar-expand-xl" style="border-bottom:2px solid white;">
            <a href="index.html" class="navbar-brand">
                <!-- <h1 class="text-white display-6">Medical HUB</h1> -->
                <img src="assets/nubians/logo.png" class="img-fluid rounded" style="width: auto;height: 100px;">
            </a>
            <button class="navbar-toggler py-2 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars text-primary"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarCollapse">
                <div class="navbar-nav d-lg-none">
                  <a [routerLink]="['']" class="nav-item nav-link">Home</a>
                 <a href="https://Over50Talks.site/" class="nav-item nav-link">Over50talks</a>
                  <a href="https://o50tweets.site/" class="nav-item nav-link">Over50tweets</a>
                  <a href="https://umyoappt.site/" class="nav-item nav-link">Booking</a>
                  <a href="https://umyoevents.site/" class="nav-item nav-link">Events</a>
                  <a [routerLink]="['/pro']" class="nav-item nav-link">Podcast</a>
                  <a href="https://upmradio.site" class="nav-item nav-link">Radio Station</a>
                  <a [routerLink]="['/ci']" class="nav-item nav-link">Card Feature</a>
                  <a [routerLink]="['/package']" class="nav-item nav-link">View Services</a>
                  <a [routerLink]="['/ai']" class="nav-item nav-link">Additional Services</a>
                   
                </div>
                <div class="d-flex m-3 ms-auto">
                    <p class="text-white" style="margin: 2px 25px;    font-size: 25px;">UMYO</p>
                    <button class="btn-search btn border border-white btn-md-square rounded-circle  me-4" data-bs-toggle="modal" data-bs-target="#searchModal"><i class="fas fa-search text-white"></i></button>
                    <a href="#" class="position-relative me-4 my-auto">
                        <i class="fa fa-shopping-cart text-white fa-2x"></i>
                        
                    </a>
                    <a href="#" class="my-auto">
                        <button class="btn btn-primary bg-white text-dark border-0 p-2">Get Started</button>
                    </a>
                </div>
            </div>
        </nav>
<nav class="navbar navbar-expand-lg d-lg-block d-none menu-nav2">
<div class="container-fluid p-0 ">

<div class="collapse navbar-collapse" id="navbarSupportedContent">
<ul class="navbar-nav mb-2 mb-lg-0">


  <li class="nav-item">
    <a [routerLink]="['']" class="nav-link">Home</a>
    </li>
    <li class="nav-item">
    <a href="https://over50talks.site/" class="nav-link">Over50talks</a>
    </li>
    <li class="nav-item">
    <a href="https://o50tweets.site/" class="nav-link">Over50tweets</a>
    </li>
    <li class="nav-item">
    <a href="https://umyoappt.site/" class="nav-link">Booking</a>
    </li>
    <li class="nav-item">
    <a href="https://umyoevents.site/" class="nav-link">Events</a>
    </li>
    <li class="nav-item">
    <a [routerLink]="['/pro']" class=" nav-link">Podcast</a>
    </li>
    <li class="nav-item">
    <a href="https://upmradio.site" class=" nav-link">Radio Station</a> 
    </li>
    <li class="nav-item">
    <a [routerLink]="['/cf']" class=" nav-link">Card Feature</a> 
    </li>
    <li class="nav-item">
    <a [routerLink]="['/package']" class=" nav-link">View Services</a> 
    </li>
       
    <li class="nav-item">
    <a [routerLink]="['/ai']" class=" nav-link">Additional Services</a>
    </li>              
                    
                   
</ul>
</div>
</div>
</nav>
    </div>
</div>
<!-- Navbar End -->
<section id="mycard">
<div class="container">
<div class="row justify-content-center mt-5">
 
  <div class="col-md-10 mb-4">
 <a href="https://umyobooks.site" class="go">
    <div class="subcard">
        <div class="card"><img class="card__img" src="assets/img/Transparency Over 50 Podcast.jpg">
                <div class="card__content text-center">
                  <h1 class="card__header">Transparency over 50 Podcast</h1>
                  <h4>Transparency over 50 Podcast</h4>
                <p class="card__text"><a href="https://live.umyoconvo.site/rooms/wce-iye-rkk-hdc/join" target="_blank">Click Here For Thursday Podcast</a></p>
                <p class="card__text">Co-host by Kreg and Tammie </p>
                <p class="card__text">Live every Thursday, please join</p>
                  <!-- <button class="card__btn">Explore <span>→</span></button> -->
                </div>
              </div>
    
          </div>
 </a>
  </div>
</div>
</div>
</section>


<!-- Modal Search Start -->
<div class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content rounded-0">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Search by keyword</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body d-flex align-items-center">
                <div class="input-group w-75 mx-auto d-flex">
                    <input type="search" class="form-control p-3" placeholder="keywords" aria-describedby="search-icon-1">
                    <span id="search-icon-1" class="input-group-text p-3"><i class="fa fa-search"></i></span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal Search End -->


<!-- Hero Start -->

    </section>
    <!-- Featurs Section End -->

