<section id="header" style="background: #1679AB !important;">
  <div class="overlay"></div>
<div class="container-fluid">
  
  <div class="container px-0">
      <nav class="navbar  navbar-expand-xl" style="border-bottom:2px solid white;">
          <a [routerLink]="['']" class="navbar-brand">
              <!-- <h1 class="text-white display-6">Medical HUB</h1> -->
              <img src="assets/nubians/logo.png" class="img-fluid rounded" style="width: auto;height: 100px;">
          </a>
          <button class="navbar-toggler py-2 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span class="fa fa-bars text-primary"></span>
          </button>
          <div class="collapse navbar-collapse " id="navbarCollapse">
              <div class="navbar-nav d-lg-none">
                 <a [routerLink]="['']" class="nav-item nav-link">Home</a>
                 <a href="https://Over50Talks.site/" class="nav-item nav-link">Over50talks</a>
                  <a href="https://o50tweets.site/" class="nav-item nav-link">Over50tweets</a>
                  <a href="https://umyoappt.site/" class="nav-item nav-link">Booking</a>
                  <a href="https://umyoevents.site/" class="nav-item nav-link">Events</a>
                  <a href="https://live.umyoconvo.site/" class="nav-item nav-link">Podcast</a>
                  <a href="https://upmradio.site" class="nav-item nav-link">Radio Station</a>
                  <a [routerLink]="['/cf']" class="nav-item nav-link">Card Feature</a>
                  <a [routerLink]="['/package']" class="nav-item nav-link">View Services</a>
                  <a [routerLink]="['/ai']" class="nav-item nav-link">Additional Services</a>
                 
              </div>
              <div class="d-flex m-3 ms-auto">
                  <p class="text-white" style="margin: 2px 25px;    font-size: 25px;">UMYO</p>
                  <button class="btn-search btn border border-white btn-md-square rounded-circle  me-4" data-bs-toggle="modal" data-bs-target="#searchModal"><i class="fas fa-search text-white"></i></button>
                  <a href="#" class="position-relative me-4 my-auto">
                      <i class="fa fa-shopping-cart text-white fa-2x"></i>
                      
                  </a>
                  <a href="#" class="my-auto">
                      <button class="btn btn-primary bg-white text-dark border-0 p-2">Get Started</button>
                  </a>
              </div>
          </div>
      </nav>
<nav class="navbar navbar-expand-lg d-lg-block d-none menu-nav2">
<div class="container-fluid p-0 ">

<div class="collapse navbar-collapse" id="navbarSupportedContent">
<ul class="navbar-nav mb-2 mb-lg-0">

<li class="nav-item">
<a [routerLink]="['']" class="nav-link">Home</a>
</li>
<li class="nav-item">
<a href="https://over50talks.site/" class="nav-link">Over50talks</a>
</li>
<li class="nav-item">
<a href="https://o50tweets.site/" class="nav-link">Over50tweets</a>
</li>
<li class="nav-item">
<a href="https://umyoappt.site/" class="nav-link">Booking</a>
</li>
<li class="nav-item">
<a href="https://umyoevents.site/" class="nav-link">Events</a>
</li>
<li class="nav-item">
<a [routerLink]="['/pro']" class=" nav-link">Podcast</a>
</li>
<li class="nav-item">
<a href="https://upmradio.site" class=" nav-link">Radio Station</a> 
</li>
<li class="nav-item">
<a [routerLink]="['/cf']" class=" nav-link">Card Feature</a> 
</li>
<li class="nav-item">
<a [routerLink]="['/package']" class=" nav-link">View Services</a> 
</li>
   
<li class="nav-item">
<a [routerLink]="['/ai']" class=" nav-link">Additional Services</a>
</li>       
    
                 
                  
                 
</ul>
</div>
</div>
</nav>
  </div>
</div>
<!-- Navbar End -->


<!-- Modal Search Start -->
<div class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
      <div class="modal-content rounded-0">
          <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Search by keyword</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body d-flex align-items-center">
              <div class="input-group w-75 mx-auto d-flex">
                  <input type="search" class="form-control p-3" placeholder="keywords" aria-describedby="search-icon-1">
                  <span id="search-icon-1" class="input-group-text p-3"><i class="fa fa-search"></i></span>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Modal Search End -->


<!-- Hero Start -->


  </section>
  <!-- Featurs Section End -->

<!-- mylist section start  -->
<section id="myservice" class="py-5">
<div class="container">
<div class="row justify-content-center">
<div class="col-lg-8 mb-5">
  <div class="mylist">
<div class="listimg">
<img src="assets/img/mindset consulting.jpeg" class="img-fluid mindset" alt="">
</div>
<div class="listcontent">
<h3>Consulting on changing the mindset</h3>
<h4 class="text-decoration-underline">"Let's commit to healthy commitments"</h4>
<p>Spiritual</p>
<p>Physical  </p>
<p>Emotional  </p>
<p>Intellectual</p>
</div>
  </div>
</div>
<div class="col-lg-4 mb-5">
  <div class="mylist">
<div class="listimg">
<img src="assets/img/Merchandise.png" class="img-fluid marchandise" alt="">
</div>
<div class="listcontent d-flex justify-content-center flex-column">
<h3>Merchandise </h3>
<p>T-shirts, books</p>
</div>
  </div>
</div>
<div class="col-lg-8 mb-lg-5 mb-0">
  <div class="mylist">
<div class="listimg">
<img src="assets/img/Non_Profits_working_together-removebg-preview.png" class="img-fluid donate" alt="">
</div>
<div class="listcontent">
<h3 class="text-decoration-underline">Donate to the nonprofit Working Together To Become One (WTTBO) </h3>
<h4>I have pictures for this page</h4>
<p>Become a silver, gold, platinum sponsor</p>
<p>We give scholarships to high school graduation seniors</p>
<p>Mentor 2 Mentor tutoring</p>
<p>Parent 2 Parent Talk</p>
<p>Fundraisers</p>
</div>
  </div>
</div>
<div class="col-lg-4 mb-lg-5 mb-0">
  <div class="mylist">
<div class="listimg">
<img src="assets/img/Let_Us_write_you-removebg-preview.png" class="img-fluid author" alt="">
</div>
<div class="listcontent d-flex justify-content-center flex-column mt-4">
<h3>Let us Write </h3>
</div>
  </div>
</div>
<div class="col-lg-8 mb-md-0 mb-4">
  <div class="mylist">
<div class="listimg">
<img src="assets/img/Chat_Talk-removebg-preview.png" class="img-fluid talk" alt="">
</div>
<div class="listcontent mt-4">
<h3 class="text-decoration-underline">Chat Talk-  </h3>
<h4> "What do you have to say?"  </h4>
<p>Send an email</p>
</div>
  </div>
</div>
<div class="col-lg-4 mb-lg-0 mb-4">
  <div class="mylist">
<div class="listimg">
<img src="assets/img/Passive Income.png" class="img-fluid income" alt="">
</div>
<div class="listcontent d-flex justify-content-center flex-column">
<h3>Residual Income </h3>
<p>Become a Notary</p>
<p>Become Your Boss</p>
</div>
  </div>
</div>
<div class="col-lg-12 text-center mt-5">
  <h2 class="book">Interested in publishing your book contact us</h2>
</div>
<div class="col-lg-10 mb-md-0 mb-4">
  <div class="mylist">
<div class="listimg">
<img src="assets/img/speaking_engagement-removebg-preview.png" class="img-fluid speak" alt="">
</div>
<div class="listcontent d-flex justify-content-center flex-column mt-4">
<h3 class="text-decoration-underline">Speaking Engagement  </h3>

<p>Are you interested in booking The Motivator Enthusiast T. Riggs for your conference? Contact
</p>
<p>I can add clippings of me speaking at a conference in Nairobi
</p>
<p>I can add clippings at other engagements as wel
</p>
</div>
  </div>
</div>
</div>
</div>
</section>